<template>
<div >
  <header-style title="Payment Gateway" />
  <b-card class="bg-card">
    <b-tabs  active-nav-item-class="font-weight-bold "
    content-class="mt-3">
      <b-tab title="All" active class="tab-nav">
      <b-card-text class=""> <allpaymentGateway/> </b-card-text>
      </b-tab>
      <b-tab title="Activated" class="mx-2">
        <b-card-text class="">
          <activated/>
        </b-card-text>
      </b-tab>
      <b-tab title="Available" class="mx-2" >
        <b-card-text class=""> <available/> </b-card-text>
      </b-tab>
    </b-tabs>
  </b-card>
  <b-modal id="modal-7" title="PayMob Configuration" size="lg" centered :hide-footer="true" >
    <div>
    <b-form  @submit.stop.prevent>
      <label for="feedback-user">Terminal ID</label>
      <b-form-input v-model="userId" :state="validation" id="feedback-user" style="width:100%;padding: 10px; margin: 0px !important;" placeholder="Enter terminal ID"></b-form-input>
      <b-form-invalid-feedback :state="validation">
        Your user ID must be 5-12 characters long.
      </b-form-invalid-feedback>
      <b-form-valid-feedback :state="validation">
        Looks Good.
      </b-form-valid-feedback>
     </b-form>

     <b-form  @submit.stop.prevent class="mt-3">
      <label for="feedback-user">Test Password</label>
      <b-form-input v-model="userpass" :state="validation2" id="feedback-user" style="width:100%;padding: 10px; margin: 0px !important;" placeholder="Enter test password"></b-form-input>
      <b-form-invalid-feedback :state="validation2">
        Your user ID must be 5-12 characters long.
      </b-form-invalid-feedback>
      <b-form-valid-feedback :state="validation2">
        Looks Good.
      </b-form-valid-feedback>
     </b-form>
     <b-form  @submit.stop.prevent class="mt-3">
      <label for="feedback-user">Live Password</label>
      <b-form-input  id="feedback-user" style="width:100%;padding: 10px; margin: 0px !important;" placeholder="Enter live password"></b-form-input>
      <!-- <b-form-invalid-feedback :state="validation2">
        Your user ID must be 5-12 characters long.
      </b-form-invalid-feedback>
      <b-form-valid-feedback :state="validation2">
        Looks Good.
      </b-form-valid-feedback> -->
     </b-form>
  </div>
  <div align="end" class="mt-4 mb-2">

    <b-button style="background-color: transparent !important; color: black; border: none; padding-inline-end: 30px;" >Cancel</b-button>
    <b-button variant="green-dark" style="width:62px" >Test</b-button>
  </div>
  </b-modal>
  <b-modal id="modal-8" title="PayMob Configuration" size="lg" centered :hide-footer="true" >
    <div>
    <b-form  @submit.stop.prevent>
      <label for="feedback-user">Terminal ID</label>
      <b-form-input v-model="userId" :state="validation" id="feedback-user" style="width:100%;padding: 10px; margin: 0px !important;" placeholder="Enter terminal ID"></b-form-input>
      <b-form-invalid-feedback :state="validation">
        Your user ID must be 5-12 characters long.
      </b-form-invalid-feedback>
      <b-form-valid-feedback :state="validation">
        Looks Good.
      </b-form-valid-feedback>
     </b-form>

     <b-form  @submit.stop.prevent class="mt-3">
      <label for="feedback-user">Test Password</label>
      <b-form-input v-model="userpass" :state="validation2" id="feedback-user" style="width:100%;padding: 10px; margin: 0px !important;" placeholder="Enter test password"></b-form-input>
      <b-form-invalid-feedback :state="validation2">
        Your user ID must be 5-12 characters long.
      </b-form-invalid-feedback>
      <b-form-valid-feedback :state="validation2">
        Looks Good.
      </b-form-valid-feedback>
     </b-form>
     <b-form  @submit.stop.prevent class="mt-3">
      <label for="feedback-user">Live Password</label>
      <b-form-input  id="feedback-user" style="width:100%;padding: 10px; margin: 0px !important;" placeholder="Enter live password"></b-form-input>
      <!-- <b-form-invalid-feedback :state="validation2">
        Your user ID must be 5-12 characters long.
      </b-form-invalid-feedback>
      <b-form-valid-feedback :state="validation2">
        Looks Good.
      </b-form-valid-feedback> -->
     </b-form>
  </div>
  <div align="end" class="mt-4 mb-2">

    <b-button style="background-color: transparent !important; color: black; border: none; padding-inline-end: 30px;" >Cancel</b-button>
    <b-button variant="green-dark" style="width:95px" >Configure</b-button>
  </div>
  </b-modal>
</div>
</template>
<script>
import HeaderStyle from '../../components/core/partials/HeaderStyle/HeaderStyle.vue'
import activated from './activated/activated.vue'
import allpaymentGateway from './all/allpaymentGateway.vue'
import Available from './available/available.vue'
export default {
  components: { allpaymentGateway, HeaderStyle, activated, Available },
  name: 'AllPayment',
  data () {
    return {
      userId: '',
      userpass: ''
    }
  },
  computed: {
    validation () {
      return this.userId.length > 4 && this.userId.length < 13
    },
    validation2 () {
      return this.userpass.length > 4 && this.userpass.length < 30
    }
  }
}
</script>
<style>

.bg-card{
background-color: transparent !important;
}
</style>

<template>
<div class="">
  <div class="header-open1">
      <b-card-header header-tag="header"  role="tab" class="card-header">
          <div class="">
            <div class="open-table">
              <div class="d-flex">
              <span><img src="../../../assets/images/noonPay.png" alt="" style="width:40px;height: 40px;border-radius: 50px;">  </span>
              <h5 class="title-header mx-2 mt-1">Noon Payment</h5>
            </div>
              <div class="d-flex">
                <b-button class="btn-edit-payment mx-2"  v-b-modal.modal-8> <i class="fas fa-pen"></i>edit</b-button>
                <!-- <b-button variant="green2" class="activated-button">
                   <span class="dot-activated"></span> -->
                  <!-- <span style="font-size:50px; " class="pb-">.</span> Activated -->
                   <!-- <div class="d-flex px-2">
                  <b-dropdown  right variant="green2"  id="dropdown-1" class="">
                  <template #button-content class="">
                    <div class="activated">
                    <span style="font-size:50px; " class="pb-3">.</span> <p>Activated</p>
                 </div>
                </template>
                  <div class="items">
                    <b-dropdown-item class="py-2 px-0" style="background-color:#0ABA68;"> Activated</b-dropdown-item>
                    <b-dropdown-item>Disable</b-dropdown-item>
                  </div>
                </b-dropdown>
              </div> -->
              <div class=" ">
          <!-- <b-form-datepicker id="example-datepicker" v-model="value" class=" p-1" text="week"></b-form-datepicker> -->
          <b-dropdown size="" variant="green2" toggle-class="text-decoration-none" >
          <template #button-content class="">
            <span style="font-size:50px; " class="">.</span> <span class="">Activated</span>
          </template>
          <b-dropdown-item class="items ">Activated</b-dropdown-item>
          <b-dropdown-item class="items">Disable</b-dropdown-item>
  </b-dropdown>
        </div>
              <!-- </b-button> -->
                <b-button v-b-toggle.accordion-1 class="icon-open"><i class="fas fa-chevron-up"></i></b-button>
              </div>
            </div>
          </div>
        </b-card-header>
      </div>

      <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
        <div class="user-list-table-payment-gateway">
          <main-table :fields="fields" :items="items" />
        </div>
      </b-collapse>
</div>
</template>
<script>
export default {
  name: 'activated',
  data () {
    return {
      fields: [
        { key: 'paymentmethod', label: 'Payment Method' },
        { key: 'country', label: 'Country', sortable: true },
        { key: 'status', label: 'Status' },
        { key: 'collected', label: 'Collected', sortable: true },
        {
          key: 'actions',
          label: this.$t('table.actions'),
          type: 'actions',
          actions: [
            // {
            //   text: this.$t('global.edit'),
            //   icon: 'fas fa-pen',
            //   color: '',
            //   ifNavigate: true,
            //   routeName: 'clients.clientView',
            //   routeParams: ['id']
            // },
            // {
            //   text: '',
            //   icon: '',
            //   color: '',
            //   ifNavigate: true,
            //   routeName: 'clients.clientView',
            //   routeParams: ['id']
            // }

            // {
            //   text: this.$t(''),
            //   icon: 'fas fa-user-lock',
            //   color: '',
            //   ifNavigate: true,
            //   routeName: 'clients.clientView',
            //   routeParams: ['id']
            // }
          ]
        }
      ],
      items: [
        { id: '20', paymentmethod: 'Apple Pay', country: 'N/A', status: 'Unavailable', collected: '0 SAR' },
        { id: '21', paymentmethod: 'Apple Pay', country: 'N/A', status: 'Unavailable', collected: '0 SAR' },
        { id: '22', paymentmethod: 'Apple Pay', country: 'N/A', status: 'Unavailable', collected: '0 SAR' }
      ],
      rows: 100,
      perPage: 1,
      currentPage: 1
    }
  }
}
</script>
<style>
.dot-activated{
  padding: 0rem 0.6rem;
  width: 6px;
  height: 7px;
  margin: 3px;
  background-color: #026F32;
  border-radius: 50px;
}
.activated-button{
border-radius: 4px;
}
.open-table{
display: flex;
justify-content: space-between;
align-items: center;

}
.header-open1{
  margin-bottom: px;
  background-color: white;
  border-bottom: none !important;
  border-radius: 8px;
}
.activated{
display: flex;
align-items: center;
width: 100px;
flex-wrap: nowrap;

}
.btn-edit-payment{
  background-color: transparent !important;
  border: 0.3px solid rgb(191, 188, 188) !important;
  color: black;
  padding: 12px 14px;
  border-radius: 4px;
}
</style>

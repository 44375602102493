<template>
  <div class="">
    <div class="header-open3">
      <div>
        <b-card-header header-tag="header"  role="tab">
          <div class="open-table">
            <div class="d-flex">
           <img src="../../../assets/images/fawrypay.png" alt="" style="width:40px;height: 40px;border-radius: 50px;">
            <h5 class="title-header mx-2 pt-1">
              Fawry</h5>
            </div>
            <div>
              <b-button class="btn-edit-payment mx-2"> <i class="fas fa-pen"></i>edit</b-button>
              <b-button variant="green-dark" class="btn-payment pt-2" v-b-modal.modal-7 >
                <i class="fas fa-plus"></i> Activate
              </b-button>
              <b-button v-b-toggle.accordion-3 class="icon-open"><i class="fas fa-chevron-up"></i></b-button>
            </div>
          </div>
        </b-card-header>
      </div>
        <b-collapse id="accordion-3" visible accordion="my-accordion" role="tabpanel">
          <div class="user-list-table-payment-gateway">
            <main-table :fields="fields" :items="items" />
          </div>
        </b-collapse>
        </div>

  </div>
  </template>
<script>
export default {
  name: 'activated',
  data () {
    return {
      fields: [
        { key: 'paymentmethod', label: 'Payment Method' },
        { key: 'country', label: 'Country', sortable: true },
        { key: 'status', label: 'Status' },
        { key: 'collected', label: 'Collected', sortable: true },
        {
          key: 'actions',
          label: this.$t('table.actions'),
          type: 'actions',
          actions: [
          // {
          //   text: this.$t('global.edit'),
          //   icon: 'fas fa-pen',
          //   color: '',
          //   ifNavigate: true,
          //   routeName: 'clients.clientView',
          //   routeParams: ['id']
          // },
          // {
          //   text: '',
          //   icon: '',
          //   color: '',
          //   ifNavigate: true,
          //   routeName: 'clients.clientView',
          //   routeParams: ['id']
          // }

          // {
          //   text: this.$t(''),
          //   icon: 'fas fa-user-lock',
          //   color: '',
          //   ifNavigate: true,
          //   routeName: 'clients.clientView',
          //   routeParams: ['id']
          // }
          ]
        }
      ],
      items: [
        { id: '20', paymentmethod: 'Apple Pay', country: 'N/A', status: 'Unavailable', collected: '0 SAR' },
        { id: '21', paymentmethod: 'Apple Pay', country: 'N/A', status: 'Unavailable', collected: '0 SAR' },
        { id: '22', paymentmethod: 'Apple Pay', country: 'N/A', status: 'Unavailable', collected: '0 SAR' }
      ],
      rows: 100,
      perPage: 1,
      currentPage: 1
    }
  }
}
</script>
<style>
  .open-table{
  display: flex;
  justify-content: space-between;
  align-items: center;

  }
  /* .available-gateway{
      margin-inline-end: 10px !important;
  } */
  .header-open3{
    background-color: white;
    border-bottom: none !important;
    border-radius: 8px;

  }
  </style>

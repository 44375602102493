<template>
  <div>
    <div class="user-list-table-payment" role="tablist">
  <div class="header-open1 mb-2">
        <activated/>
      </div>
      <!-- <div> -->

        <!-- <b-card-header header-tag="header" class="header-open2" role="tab">
          <div class="open-table">
            <h5 class="title-header">OPay</h5>
            <div>
              <b-button class="btn-edit-payment mx-2"> <i class="fas fa-pen"></i>edit</b-button>
              <b-button variant="light2" class="btn-payment">
                <i class="fas fa-wifi-1"></i> Requested
              </b-button>
              <b-button v-b-toggle.accordion-2 class="icon-open"><i class="fas fa-chevron-up"></i></b-button>
            </div>
          </div>
        </b-card-header>
        <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
          <div class="user-list-table-payment-gateway">
            <main-table :fields="fields" :items="items" />
          </div>
        </b-collapse> -->

    <!-- </div> -->
     <div class="mb-2">
        <available/>
      </div>

        <b-card-header header-tag="header" class="header-open4" role="tab">
          <div class="d-flex justify-content-between">
            <div class="open-table">
             <img src="../../../assets/images/noonPay.png" alt="" style="width:40px;height: 40px;border-radius: 50px;"> <h5 class="title-header mx-2">  Noon Payments</h5>

            </div>
            <div class="d-flex">
              <b-button class="btn-edit-payment mx-2"> <i class="fas fa-pen"></i>edit</b-button>
              <div class=" ">
          <!-- <b-form-datepicker id="example-datepicker" v-model="value" class=" p-1" text="week"></b-form-datepicker> -->
          <b-dropdown size="" variant="dangerLight" toggle-class="text-decoration-none" class="btn-disable-payment">
          <template #button-content class="">
            <span style="font-size:50px; " class="">.</span> <span class="">Disable</span>
          </template>
          <b-dropdown-item class="items ">Activated</b-dropdown-item>
          <b-dropdown-item class="items">Disable</b-dropdown-item>
  </b-dropdown>
        </div>
              <b-button v-b-toggle.accordion-4 class="icon-open"><i class="fas fa-chevron-up"></i></b-button>
            </div>
          </div>
        </b-card-header>

      <b-collapse id="accordion-4" visible accordion="my-accordion" role="tabpanel">
        <div class="user-list-table-payment-gateway">
          <main-table :fields="fields" :items="items" />
        </div>
      </b-collapse>
    </div>
  </div>
</template>
<script>
import activated from '../activated/activated.vue'
import Available from '../available/available.vue'
export default {
  components: { activated, Available },
  name: 'allpaymentGateway',
  data () {
    return {
      fields: [
        { key: 'paymentmethod', label: 'Payment Method' },
        { key: 'country', label: 'Country', sortable: true },
        { key: 'status', label: 'Status' },
        { key: 'collected', label: 'Collected', sortable: true },
        {
          key: 'actions',
          label: this.$t('table.actions'),
          type: 'actions',
          actions: [
            // {
            //   text: this.$t('global.edit'),
            //   icon: 'fas fa-pen',
            //   color: '',
            //   ifNavigate: true,
            //   routeName: 'clients.clientView',
            //   routeParams: ['id']
            // },
            // {
            //   text: '',
            //   icon: '',
            //   color: '',
            //   ifNavigate: true,
            //   routeName: 'clients.clientView',
            //   routeParams: ['id']
            // }

            // {
            //   text: this.$t(''),
            //   icon: 'fas fa-user-lock',
            //   color: '',
            //   ifNavigate: true,
            //   routeName: 'clients.clientView',
            //   routeParams: ['id']
            // }
          ]
        }
      ],
      items: [
        { id: '20', paymentmethod: 'Apple Pay', country: 'N/A', status: 'Unavailable', collected: '0 SAR' },
        { id: '21', paymentmethod: 'Apple Pay', country: 'N/A', status: 'Unavailable', collected: '0 SAR' },
        { id: '22', paymentmethod: 'Apple Pay', country: 'N/A', status: 'Unavailable', collected: '0 SAR' }
      ],
      rows: 100,
      perPage: 1,
      currentPage: 1
    }
  }
}
</script>

<style>
.card-header{
  border: none !important;
}
.header-open1{

  background-color: white;
  border-bottom: none !important;
}
.header-open2{

  background-color: white;
}
.header-open3{
  background-color: white;
}
.header-open4{

  background-color: white;
}

.btn-disable-payment{
  width: 123px !important;
  height:40px;
  border: none;
  padding: 0px !important;
  border-radius: 4px;
}
.btn-disable-payment:hover{
  color:#F50F10;
}
.btn-payment{
  /* padding: 18px 20px !important; */
  width: 124px !important;
  height: 40px !important;
}
.btn-activate-payment{
  padding: 0px 0px !important;
  width: 124px;
  height:40px;
  white-space: nowrap;

}
.icon-dot{
  background-color: black !important;
}
.btn-edit-payment{
  background-color: transparent !important;
  border: 0.3px solid rgb(191, 188, 188) !important;
  color: black;
  padding: 12px 14px;
  border-radius: 4px;
}
.btn-edit-payment:hover{
  color: black;
}
.btn-edit-payment:focus{
  color: black;
}
.module-action{
  padding: 0 !important;
  display: flex !important;
}
.title-header{

}
.header-open{
border: none;
margin-bottom: 5px !important;
background-color: white;
}
.open-table{
display: flex;
justify-content: space-between;
align-items: center;

}
.icon-open{
  background-color: transparent !important;
  color: black;
  border: none;
}
.icon-open:hover{
  color: black;
}
.icon-open:focus{
color: black;
}
.user-list-table-payment{
  /* box-shadow: 0 4px 12px 2px rgba(102, 102, 102, 0.16); */
  margin-inline-end: 20px !important;
  margin-inline-start: 10px !important;
  border-radius: 8px ;
  margin-top:20px ;
}
.user-list-table-payment-gateway{
  background-color: white;
  margin-bottom: 20px;
}
</style>
